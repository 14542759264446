import React, { useState } from 'react';
import DynamicForm from '../../components/dynamicform';
import Footer from '../../components/footer';
import NGLVHeader from '../../components/header';
import { emailsignup } from '../../../services/emailsignupService';
import { toast } from 'react-toastify';


const EmailSignup = () => {
  const [btndis,setBtndis] = useState(false)
    const fields = [
        {
          name: 'name',
          label: 'Name',
          type: 'text',
          required:false
        },
        {
          name: 'email',
          label: 'Email',
          type: 'text',
          required:true
        },
        {
          name: 'address',
          label: 'Address',
          type: 'textarea',
          required:false
        }
      ];

      const handleSubmit = async(formData) => {
        setBtndis(true)
        // Handle form submission here
        console.log(formData);
        try {
         const response = await emailsignup(formData);
         if(response){
          setBtndis(false)
            toast.success('Email Send to You in email')
         }
        } catch (ex) {
          toast.error(ex.msg)
          setBtndis(false)
        }
      };
    return ( 
        <>
            <div>
              <NGLVHeader />
                {/* <header>
                    <h2>Jesus Email Signup</h2>
                </header> */}
                <section id="intro" class="content-section">
                <div>
                  <img src={require('../../../assets/NGLV_images/home_banner.png')} style={{ width: '100%', height: '350px' }} />
                </div>
                <div className='container-fluid content'>
                  <div>
                    <p className='fsize' style={{textAlign:'center'}}>Jesus Christ is the most remarkable person who ever lived, and we can understand him better by considering seven things he said about himself—all in the Gospel of John.</p>
                  </div>
                  <div style={{textAlign:'center'}}>
                    <p className='fsize' style={{fontStyle:'italic',lineHeight:0.2}}>I am the bread of life,</p>
                    <p className='fsize' style={{fontStyle:'italic',lineHeight:0.2}}>I am the light of the world,</p>
                    <p className='fsize' style={{fontStyle:'italic',lineHeight:0.2}}>I am the gate for the sheep,</p>
                    <p className='fsize' style={{fontStyle:'italic',lineHeight:0.2}}>I am the good shepherd,</p>
                    <p className='fsize' style={{fontStyle:'italic',lineHeight:0.2}}>I am the resurrection and the life,</p>
                    <p className='fsize' style={{fontStyle:'italic',lineHeight:0.2}}>I am the way and the truth and the life,</p>
                    <p className='fsize' style={{fontStyle:'italic',lineHeight:0.2}}>I am the true vine.</p>
                  </div>
                  <div>
                    <p className='fsize'>These seven statements reveal who Jesus is and how we should think about him.</p>
                  </div>
                  <div>
                    <p className='fsize'>Want to learn more? Sign up to receive seven emails, one-a-day, for the next week to discover more about Jesus and why he matters to you and the world.</p>
                  </div>
                  <div >
                    <DynamicForm btndisa={btndis} fields={fields} onSubmit={handleSubmit} apistring="emailsignup" />
                  </div>
                  
                </div>
                </section>
                </div>
                <Footer />
        </>
     );
}
 
export default EmailSignup;