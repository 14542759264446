import React, { Component,useState,useEffect } from 'react';
import DynamicForm from '../../components/dynamicform';
import Footer from '../../components/footer';
import NGLVHeader from '../../components/header';
import { johnbooklet } from '../../../services/gospelofjohnbookletService';
import { toast } from 'react-toastify';

const BookletForm = () => {
  const [btndis,setBtndis] = useState(false);

    const fields = [
        {
          name: 'name',
          label: 'Name',
          type: 'text',
          required:true
        },
        {
          name: 'email',
          label: 'Email',
          type: 'text',
          required:false
        },
        {
          name: 'address',
          label: 'Address',
          type: 'textarea',
          required:true
        }
      ];
     
      // useEffect(() => {
     
      // },[])
    
      const handleSubmit = async(formData) => {
        // Handle form submission here
        console.log(formData,'formDtata');
        setBtndis(true)
       try {
        const response = await johnbooklet(formData);
        if(response){
          toast.success('Answers Submitted SuccessFully')
          setBtndis(false)
        }
       } catch (ex) {
        toast.error(ex.msg)
        setBtndis(false)
       }
      };
    return ( 
        <>
        <div>
          <NGLVHeader />
            {/* <header>
                <h2>Booklet Form</h2>
            </header> */}
            <section id="intro" class="content-section">
            <div >
              <img src={require('../../../assets/NGLV_images/home_banner.png')} style={{ width: '100%', height: '350px' }} />
            </div>
            <p className='fsize'>Please fill out the form to receive your FREE paper booklet of The Gospel of John.</p>
            <p className='fsize'>After submitting the form, we will mail you a copy of the booklet.</p>
            <div className='container-fluid content'>
               <DynamicForm fields={fields} btndisa={btndis} onSubmit={handleSubmit} apistring='johnbooklet' />
            </div>
            </section>
            </div>
            <Footer />
    </>
     );
}
 
export default BookletForm;